<script>
import Switcher from "@/components/switcher";
import { HomeIcon, MailIcon } from "vue-feather-icons";

/**
 * Auth-re-password component
 */
export default {
  data() {
    return {};
  },
  components: {
    Switcher,
    MailIcon,
    HomeIcon,
  },
};
</script>

<template>
  <div>
    <div class="back-to-home rounded d-none d-sm-block">
      <router-link to="/" class="btn btn-icon btn-soft-primary">
        <home-icon class="icons"></home-icon>
      </router-link>
    </div>

    <!-- Hero Start -->
    <section class="bg-home d-flex align-items-center">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-7 col-md-6">
            <div class="mr-lg-5">
              <img
                src="/images/user/recovery.svg"
                class="img-fluid d-block mx-auto"
                alt=""
              />
            </div>
          </div>
          <div class="col-lg-5 col-md-6">
            <div class="card shadow rounded border-0">
              <div class="card-body">
                <h4 class="card-title text-center">Sesión Finalizada</h4>

                <form class="login-form mt-4">
                  <div class="row">
                    <div class="col-lg-12">
                      <p class="text-muted">
                        Tu sesión ha sido finalizada por seguridad, para volver a iniciar, busca en tu bandeja de correo 
                        electrónico el link de inicio y teclea tu código de acceso.
                      </p>
                    </div>
                    <div class="col-lg-12">
                      <router-link to="/" class="btn btn-primary btn-block">Regresar a Inicio</router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->
    <!--Switcher /-->
  </div>
</template>
